import {AnchorHTMLAttributes, ComponentProps, PropsWithChildren} from "react";
import clsx from "clsx";
import {IconArrowUpRight} from "@tabler/icons-react";
import {Link} from "@inertiajs/react";

export default function A({className, externalIcon = false, ...props}: AnchorHTMLAttributes<HTMLAnchorElement> & { externalIcon?: boolean }) {

    return (
        <a {...props} className={clsx(className, "text-brand-600 hover:underline", externalIcon && "group")}>
            {props.children}
            {externalIcon &&
                <IconArrowUpRight size={20} className="inline transition-transform duration-75 mb-[1px] ml-0.5 transform group-hover:translate-x-[1px] group-hover:-translate-y-[1px]"/>
            }
        </a>
    );

}

// FIXME merge with main component ...
export function ALink({className, externalIcon = false, ...props}: PropsWithChildren & { href: string, className?: string, externalIcon?: boolean }) {

    return (
        <Link {...props} className={clsx(className, "text-brand-600 hover:underline", externalIcon && "group")}>
            {props.children}
            {externalIcon &&
                <IconArrowUpRight size={20} className="inline transition-transform duration-75 mb-[1px] ml-0.5 transform group-hover:translate-x-[1px] group-hover:-translate-y-[1px]"/>
            }
        </Link>
    );

}

// FIXME merge with main component ...
export function AButton({className, externalIcon = false, ...props}: ComponentProps<'button'> & { externalIcon?: boolean }) {

    return (
        <button {...props} className={clsx(className, "text-brand-600 hover:underline", externalIcon && "group")}>
            {props.children}
            {externalIcon &&
                <IconArrowUpRight size={20} className="inline transition-transform duration-75 mb-[1px] ml-0.5 transform group-hover:translate-x-[1px] group-hover:-translate-y-[1px]"/>
            }
        </button>
    );

}
