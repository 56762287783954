import React, {PropsWithChildren} from 'react';
import {TermsConsent} from "@/Globals/TermsConsent";
import {ToasterList} from "@/Globals/ToasterList";
import {ExpiredSession} from "@/Globals/ExpiredSession";
import {usePage} from "@inertiajs/react";
import {PageProps} from "@/types";
import EnvironmentBadge from "@/Components/EnvironmentBadge";

export default function BaseLayout({children}: PropsWithChildren) {

    const {environment} = usePage<PageProps>().props;

    return (<>
        <EnvironmentBadge className="mr-auto sm:hidden"/>
        <TermsConsent />
        <ExpiredSession />
        {children}
        <ToasterList />
    </>);
}
