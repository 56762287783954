import {Link, usePage} from "@inertiajs/react";
import {ComponentProps, ElementType, HTMLAttributes} from "react";
import clsx from "clsx";
import {PageProps} from "@/types";
import CompellioLogo from "../../assets/compellio.svg?react";
import {consent} from "@/common";

function FooterLink({className, children, as = Link, ...props }: ComponentProps<typeof Link | 'button'> & { as?: ElementType }) {
    const Component = as ?? Link;
    return (
        <Component {...props} className={clsx("text-xs text-stone-500 hover:text-stone-900 hover:underline", className)}>{children}</Component>
    );
}

export default function Footer({ className }: HTMLAttributes<HTMLDivElement>) {
    const { supportHref } = usePage<PageProps>().props;
    return (
        <footer className={clsx("flex flex-col items-center py-5 gap-3 relative", className)}>
            <a href="https://compell.io" target="_blank" className="inline-flex justify-center items-center text-sm gap-2">
                Brought to you by <CompellioLogo className="h-5"/>
            </a>
            <div className="flex flex-wrap justify-center items-center gap-2.5">
                <FooterLink href={route('legal.terms')}>Terms of Service</FooterLink>
                <FooterLink href={route('legal.privacy')}>Privacy Policy</FooterLink>
                <FooterLink href={route('legal.cookies')}>Cookie Policy</FooterLink>
                {!!consent().loaded &&
                    <FooterLink as="button" onClick={consent().openChoices}>Cookie Preferences</FooterLink>
                }
                {supportHref &&
                    <FooterLink as="a" href={supportHref}>Support</FooterLink>
                }
            </div>
            <div className="text-xs text-stone-500">
                © 2024 Compellio S.A. All rights reserved.
            </div>
        </footer>
    );
}
