import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";
import clsx from "clsx";

export default function Logo(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
    return (
        <div className={clsx("font-display font-medium tracking-tight [text-wrap:balance] text-2xl text-transparent bg-clip-text bg-gradient-to-bl from-brand-400 to-brand-600 mt-[3px]", props.className)}>
            AlmaMint
        </div>

        // <svg {...props} viewBox="0 0 599.041 74.67" xmlns="http://www.w3.org/2000/svg">
        //     <path
        //         d="M589.775 439.715v-74.667h6.507l34.133 58.24h-3.413l13.127-22.61 20.686-35.63h6.507v74.667h-7.574v-62.827h1.814l-31.147 53.334h-3.733l-31.36-53.334h2.026v62.827zm101.867 0v-74.667h7.893v74.667zm32.213 0v-74.667h6.507l51.52 64.854h-3.413v-64.854h7.787v74.667h-6.507l-51.414-64.853h3.414v64.853zm101.227 0v-67.84h-26.24v-6.827h60.374v6.827h-26.24v67.84z"
        //         fill="currentColor"
        //         transform="translate(-260.171 -365.049)"
        //     />
        //     <path
        //         d="m260.171 439.716 34.134-74.667h7.786l34.134 74.667h-8.32l-31.36-70.08h3.2l-31.36 70.08zm88.427 0v-74.667h7.893v67.84h41.814v6.827zm62.827 0v-74.667h6.507l34.133 58.24h-3.413l33.813-58.24h6.507v74.667h-7.573v-62.827h1.813l-31.147 53.333h-3.733l-31.36-53.333h2.026v62.827zm89.92 0 34.134-74.667h7.786l34.134 74.667h-8.32l-31.36-70.08h3.2l-31.36 70.08z"
        //         aria-label="ALMAMINT"
        //         fill="currentColor"
        //         transform="translate(-260.171 -365.049)"
        //     />
        // </svg>
    );
}
