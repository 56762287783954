import Modal from "@/Components/Modal";
import Button, {ButtonScale, ButtonTheme} from "@/Components/Button";
import Avatar from "@/Components/Auth/Avatar";
import {usePage} from "@inertiajs/react";
import {PageProps} from "@/types";
import {Dialog} from "@headlessui/react";
import {formatAccount, username} from "@/common";
import GuardIcon from "@/Components/Auth/GuardIcon";
import clsx from "clsx";
import {getChain} from "@/wagmi";

export default function AccountDialog({open, onClose}: { open: boolean, onClose: { (): void } }) {
    const {props} = usePage<PageProps>();
    const auth = props.auth;

    const network = getChain(props.networks.default);

    const title = username(props);
    const subtitle = auth.guard.name == "wallet"
        ? (!auth.user.name) ? network.name : formatAccount(auth.identifier)
        : auth.identifier;

    return (
        <Modal open={open} onClose={onClose}>
            <div className="flex flex-col gap-8 py-1">

                <div>
                    <div className="flex gap-4 items-center">

                        <div className="relative">
                            <Avatar size={60}/>
                            <div
                                className={clsx(["absolute bottom-0 right-0 rounded-full p-1 bg-white ring ring-white"])}>
                                <GuardIcon size={22}/>
                            </div>
                        </div>

                        <div className="min-w-0">
                            <Dialog.Title as="h3"
                                          className="text-2xl font-semibold font-display truncate">{title}</Dialog.Title>
                            <p className="text-stone-700 leading-tight">{subtitle}</p>
                        </div>

                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <Button as="button" method="post" href={route('logout')}
                            onClick={onClose} scale={ButtonScale.Large}
                            theme={ButtonTheme.Secondary}>Disconnect</Button>
                </div>

            </div>
        </Modal>
    )
}
