import {router} from "@inertiajs/react";
import Modal from "@/Components/Modal";
import Button, {ButtonScale, ButtonTheme} from "@/Components/Button";
import React, {useState} from "react";

export function ExpiredSession() {

    const [expired, setExpired] = useState(false);

    router.on('invalid', (event) => {
        if (event.detail.response.status === 419) {
            event.preventDefault();
            setExpired(true);
        }
    })

    const reload = () => {
        router.reload();
        setExpired(false);
    }

    return (
        <>
            <Modal open={expired} disableClose={true}>

                <div className="text-center">
                    <h4 className="text-lg font-semibold mb-1">An error occurred</h4>
                    <p className="text-stone-700">The page expired, please try again.</p>
                </div>

                <div className="mt-5 sm:mt-6 flex flex-col gap-2">
                    <Button as="button" autoFocus={true} onClick={reload} scale={ButtonScale.Large}
                            theme={ButtonTheme.Secondary}>Try again</Button>
                </div>
            </Modal>
        </>
    );

}
