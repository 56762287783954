import {useForm, usePage} from "@inertiajs/react";
import {PageProps} from "@/types";
import Modal from "@/Components/Modal";
import Button, {ButtonScale, ButtonTheme} from "@/Components/Button";
import A from "@/Components/A";
import React, {FormEventHandler} from "react";

export function TermsConsent() {

    const {props: {requireTermsConsent}} = usePage<PageProps>();

    const {post, hasErrors, clearErrors} = useForm({
        // FIXME replace hard-coded values with constants
        key: 'terms',
        version: requireTermsConsent ? requireTermsConsent.version : '0'
    })

    const submit: FormEventHandler = (e) => {
        e.preventDefault();
        clearErrors();

        post(route('consent.store'));
    }

    return (
        <>
            <Modal open={!!requireTermsConsent} disableClose={true}>
                <div className="py-2">
                    By connecting your email or wallet and using AlmaMint, you agree to our <A href={route('terms')} target="_blank">Terms of Service</A> and <A href={route('legal.privacy')} target="_blank">Privacy Policy</A>.
                    {hasErrors && <p className="mt-2 text-flamingo-700">An error occurred. Please try again.</p>}
                </div>
                <form onSubmit={submit} className="mt-5 sm:mt-6 flex flex-col gap-2">
                    <Button as="button" autoFocus={true} scale={ButtonScale.Large} theme={ButtonTheme.Primary}>Accept and continue</Button>
                    <Button as="button" method="post" href={route('logout')} scale={ButtonScale.Large} theme={ButtonTheme.Transparent}>Disconnect</Button>
                </form>
            </Modal>
        </>
    );

}
