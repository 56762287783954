import {usePage} from "@inertiajs/react";
import {PageProps} from "@/types";
import {IconMail} from "@tabler/icons-react";
import NetworkIcon from "@/Components/NetworkIcon";

export default function GuardIcon({size = 20, color = true}: { size?: number, color?: boolean }) {

    const {props} = usePage<PageProps>();

    switch (props.auth.guard.name) {

        case 'email':
            return <IconMail size={size}/>;

        case 'wallet':
            return <NetworkIcon network={props.auth.guard.network!} size={size} color={color}/>

        default:
            return <></>

    }

}
