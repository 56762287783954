import PolygonLogo from "../../assets/polygon-symbol.svg?react";
import EthereumLogo from "../../assets/ethereum-symbol.svg?react";
import AvalancheLogo from "../../assets/avalanche-symbol.svg?react";
import OptimismLogo from "../../assets/optimism-logo.svg?react";
import clsx from "clsx";

export default function NetworkIcon({network, size = 20, color = true}: {
    network: string,
    size?: number,
    color?: boolean
}) {

    switch (network!) {
        case 'mumbai':
        case 'amoy':
        case 'polygon':
            return <PolygonLogo height={size - 2} width={size - 2} className={clsx(color && "text-[#7B3FE4]")}/>;

        case 'avalanche':
            return <AvalancheLogo height={size - 2} width={size - 2} className={clsx(color && "text-[#E84142]")}/>;

        case 'optimism':
            return <OptimismLogo height={size - 2} width={size - 2} className={clsx(color && "text-[#FF0420]")}/>;

        default:
            return <EthereumLogo height={size - 2} width={size - 2} className={clsx(color && "text-[#3C3C3D]")}/>
    }

}
