import {default as BoringAvatar} from "boring-avatars";
import {ComponentProps} from "react";
import {usePage} from "@inertiajs/react";
import {PageProps} from "@/types";

export default function Avatar({size = 30, ...props}: ComponentProps<'div'> & { identifier?: string, size?: number }) {

    if (!props.identifier) {
        props.identifier = usePage<PageProps>().props.auth.user.avatar_hash;
    }

    return (
        <div {...props}>
            <BoringAvatar
                name={props.identifier}
                size={size}
                variant="marble"
            />
        </div>
    );

}
