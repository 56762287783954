import React from "react";
import {resolveValue, toast, ToastBar, Toaster} from "react-hot-toast";
import {IconX} from '@tabler/icons-react';
import Button, {ButtonTheme} from "@/Components/Button";

export function ToasterList() {
    return (
        <Toaster
            position="top-center"
            reverseOrder={true}
            gutter={6}
            toastOptions={{
                duration: 5000,
                className: '!rounded-xl !shadow-lg ring-1 ring-stone-800/10',
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({icon, message}) => (
                        <>
                            {icon}
                            <div className={'pl-2 pr-3'}>
                                {resolveValue(t.message, t)}
                            </div>
                            {t.type !== 'loading' && (
                                <Button theme={ButtonTheme.Transparent} className="!p-1"
                                        onClick={() => toast.dismiss(t.id)}>
                                    <IconX size={16}/>
                                </Button>
                            )}
                        </>
                    )}
                </ToastBar>
            )}
        </Toaster>
    );
}
