import React from "react";
import {usePage} from "@inertiajs/react";
import {PageProps} from "@/types";
import clsx from "clsx";

export default function EnvironmentBadge({className}: { className: string }) {

    const {environment} = usePage<PageProps>().props;

    return environment === 'production' ? null : (
        <div className={clsx(className, "bg-sky-600 text-white uppercase font-semibold text-center px-3 py-1 text-xs")}>
            {environment}
        </div>
    );
}
