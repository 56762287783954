import {Link} from "@inertiajs/react";
import Logo from "@/Components/Logo";
import AccountControl from "@/Components/Auth/AccountControl";
import {ReactNode} from "react";
import EnvironmentBadge from "@/Components/EnvironmentBadge";

export default function Header({accountControlOverride = null}: { accountControlOverride?: ReactNode }) {

    return (
        <header className="py-2 flex grow gap-4 justify-between  items-center px-7">

            <Link href={route('home')} className="my-3">
                <Logo/>
            </Link>

            <EnvironmentBadge className="rounded-xl hidden sm:block mr-auto"/>

            <div className="flex items-center gap-4">
                {/*<ThemeToggle />*/}
                {accountControlOverride || <>
                    <Link href={route('faq')} className="text-stone-700 text-sm hover:underline mr-1 font-medium">FAQ</Link>
                    <AccountControl/>
                </>}
            </div>

        </header>
    );

}
