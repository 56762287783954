import {usePage} from "@inertiajs/react";
import Button, {ButtonTheme} from "@/Components/Button";
import {PageProps} from "@/types";
import {useState} from "react";
import AccountDialog from "@/Components/Auth/AccountDialog";
import Avatar from "@/Components/Auth/Avatar";
import GuardIcon from "@/Components/Auth/GuardIcon";
import clsx from "clsx";

export default function AccountControl() {

    const {props} = usePage<PageProps>();

    const [open, setOpen] = useState(false);

    return (<>

            {!props.auth.user && <>
                <Button href={route('login.wallet')}><span>Connect<span className="hidden sm:inline"> wallet</span></span></Button>
            </>}

            {props.auth.user && <>
                <Button onClick={() => setOpen(true)} theme={ButtonTheme.Transparent} className="rounded-full !px-1.5 group">

                    <div className="relative overflow-hidden">
                        <Avatar size={35}/>

                        <div className={clsx([
                            "text-stone-700 absolute bottom-0 right-0 bg-stone-100 rounded-full ring-4",
                            "ring-stone-100", // FIXME breaks page background color encapsulation
                            "transition group-hover:bg-[#eeedec] group-hover:ring-[#eeedec]" // FIXME breaks button hover color encapsulation
                        ])}>
                            <GuardIcon size={16}/>
                        </div>
                    </div>

                </Button>

                <AccountDialog open={open} onClose={() => setOpen(false)}/>
            </>}

        </>
    );

}
